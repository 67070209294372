
export function Header() {
    return (
      <header className="header-area header-sticky wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav className="main-nav">
                  <a href="/">
                  <img src="assets/favicon.ico" style={{"width": "95px"}}></img>
                  </a>
                  <ul className="nav">
                    {/* <li className="scroll-to-section"><a href="#top" className="active">Home</a></li> */}
                    <li className="scroll-to-section"><a href="/#services">Features</a></li>
                    <li className="scroll-to-section"><a href="/#about">Documentation</a></li>
                    <li className="scroll-to-section"><a href="/#pricing">Pricing</a></li>
                    <li className="scroll-to-section"><a href="/#newsletter">Affiliates</a></li>
                    <li><div className="gradient-button"><a id="modal_trigger" href="/login"><i className="fa fa-sign-in-alt"></i> Sign In</a></div></li> 
                  </ul>        
                  <a className='menu-trigger'>
                      <span>Menu</span>
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </header>)
}
