// import logo from './logo.svg';
import './App.css';
import { Header } from '../header';
import { Footer } from '../footer';

function PrivacyPolicy() {
    return (
        <>
            <Header />
            <div className="ftco-section" id="top" data-wow-duration="1s" data-wow-delay="0.5s">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            Privacy Policy
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default PrivacyPolicy;
