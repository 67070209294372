// import logo from './logo.svg';
import './App.css';
import { Header } from '../header';
import { Footer } from '../footer';

function NotFoundPage() {
  return (
   

<>
   
    <Header />
    
    <div className="main-banner wow fadeIn" id="top" data-wow-duration="1s" data-wow-delay="0.5s">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="left-content show-up header-text wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                  <div className="row">
                    <div className="col-lg-12">
                    <h2>404 Not Found <br></br></h2>
                    <h3>What you looking is not here.  <br></br></h3>
                    <h3></h3>
                      <br></br>
                      <h4></h4>
                      <br></br>
                    </div>
                    <div className="col-lg-12">
                      <div className="white-button first-button scroll-to-section">
                        <a href="#contact">Get started <i className="fab fa-next"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="right-image wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
</>
  
    
  );
}

export default NotFoundPage;
