export function Footer(){
    return (
        <footer id="newsletter">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="section-heading">
              <h4>Join our mailing list to receive the news &amp; latest trends</h4>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-3">
            <form id="search" action="#" method="GET">
              <div className="row">
                <div className="col-lg-6 col-sm-6">
                  <fieldset>
                    <input type="address" name="address" className="email" placeholder="Email Address..." autocomplete="on" required/>
                  </fieldset>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <fieldset>
                    <button type="submit" className="main-button">Subscribe Now <i className="fa fa-angle-right"></i></button>
                  </fieldset>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="footer-widget">
              <h4>Contact Us</h4>
              <p>Building Number 145, <br></br>Sector 44,<br></br> Gurugram, Haryana 122003</p>
              <p><a href="#">NA</a></p>
              <p><a href="#">info@aijustis.com</a></p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-widget">
              <h4>About Us</h4>
              <ul>
                <li><a href="/login">Sign in / Sign up</a></li>
                <li><a href="/#services">Services</a></li>
                <li><a href="/#about">About</a></li>
                <li><a href="/#pricing">Pricing</a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-widget">
              <h4>Useful Links</h4>
              <ul>
                <li><a href="#">Privacy Policy</a></li>
                <li><a href="#">Careers</a></li>
              </ul>
              <ul>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-widget">
              <h4>About Our Company</h4>

              <p>With AI justis, legal research that used to take hours can now be done in minutes, allowing lawyers to focus on strategy and advocacy.</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="copyright-text">
              <p>© 2024 AI Justis Pvt Ltd. All rights reserved.
           </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    )
}