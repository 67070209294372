import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFoundPage from './pages/404';
import Main from './pages/App';
import Login from './pages/login';
import PrivacyPolicy from './pages/pp';
import Chat from './pages/Chat/chat';
import ComingSoon from './pages/comingsoon';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/login' element={<Login />} />
          <Route path="/*" element={<NotFoundPage />} />
          <Route path="/pp" element={<PrivacyPolicy />} />
          <Route path='/chat' element={<Chat />} />
          <Route path='/coming' element={<ComingSoon/>} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
