

import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState ,useSocketIO } from 'react-use-websocket';
import './chat.css'

function Chat() {
  
    const [socketUrl, setSocketUrl] = useState('ws://localhost:8080/');
    const [messageHistory, setMessageHistory] = useState([]);
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl);

    useEffect(() => {
        if (lastJsonMessage !== null) {
            console.log(lastJsonMessage);
            setMessageHistory([ ...messageHistory ,lastJsonMessage])
        }
    }, [lastJsonMessage]);

    const handleClickChangeSocketUrl = useCallback(
        () => setSocketUrl('ws://localhost:8080/'),
        []
    );
    // handleClickChangeSocketUrl();
    const handleClickSendMessage =useCallback((e) => {
        sendJsonMessage('Hello');
        console.log(e);
        // e.preventDefault();
    }, []);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: '',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    console.log(messageHistory);
    // messageHistory.filter((msg) => {
    //    console.log(msg)
    // }); 
    return (
        <>
            <div class="body">
                <section class="msger">
                    <header class="msger-header">
                        <div class="msger-header-title">
                            <img src="../assets/favicon-32x32.png"></img>
                        </div><span>{connectionStatus}</span>
                        <div class="msger-header-options">
                            <span><i class="fas fa-cog"></i></span>
                        </div>
                    </header>
                    <main class="msger-chat">
                    {messageHistory.reverse().map((response, idx) =>(
                        <div class="msg left-msg">
                            <div
                                    class="msg-img"
                                    style={{backgroundImage: "url("+response.profile.avatar+")" }}
                                ></div>

                                <div class="msg-bubble">
                                    <div class="msg-info">
                                        <div class="msg-info-name">{response ? response.profile.name : null}</div>
                                        <div class="msg-info-time">{response ? new Date(response.timestamp).toLocaleDateString() : null}</div>
                                    </div>

                                    <div class="msg-text">
                                        {response ? response.message : null} 😄
                                    </div>
                                </div>
                            </div>
                             ))}

                        <div class="msg right-msg">
                            <div
                                class="msg-img"
                                style={{ "background-image": "url(https://static.vecteezy.com/system/resources/previews/028/569/170/original/single-man-icon-people-icon-user-profile-symbol-person-symbol-businessman-stock-vector.jpg)" }}
                            ></div>

                            <div class="msg-bubble">
                                <div class="msg-info">
                                    <div class="msg-info-name">+91xxxxxxxx</div>
                                    <div class="msg-info-time">12:46</div>
                                </div>

                                <div class="msg-text">
                                    Police beat me badly. I am living in fear.
                                </div>
                            </div>
                        </div>
                    </main>

                    <form class="msger-inputarea" onSubmit={handleClickSendMessage }>
                        <textarea type="text" class="form-control" placeholder="Write you question" required="" />
                        <button type="submit" disabled={readyState !== ReadyState.OPEN} class="msger-send-btn uil uil-message" style={{ "padding": "0px" }} >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-white dark:text-black"><path d="M7 11L12 6L17 11M12 18V7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        </button>
                    </form>
                </section>
            </div>
        </>
    );
}

export default Chat;
