// import logo from './logo.svg';
import './commingsoon.css';
import React, { useState, useEffect } from 'react';
import { Header } from '../header';
import { Footer } from '../footer';

function ComingSoon() {
    const [time, setTime] = useState(new Date("30 May 2024").getTime() - new Date().getTime());

    useEffect(() => {
        const interval = setInterval(() => {
            var distance = new Date(new Date("30 May 2024").getTime() - new Date().getTime())
            setTime(distance)
        }, 1000);
        return () => {
          clearInterval(interval);
        };
      }, []);

    

    return (
        <>
            <Header />
            <div className="ftco-section" id="top" data-wow-duration="1s" data-wow-delay="0.5s">
                <div className="container">
                    
                    <div id="intro" class="p-5 text-center bg-image shadow-1-strong"
                         >
                        <div class="mask" >
                            <div class="d-flex justify-content-center align-items-center h-100">
                                <div class="text-white px-4" data-mdb-theme="dark">
                                    <h1 class="mb-3">Coming Soon!</h1>

                                    {/* <!-- Time Counter --> */}
                                    <h3 id="time-counter" class="border border-light my-4 p-4">{
                                        // time+
                                        Math.floor(new Date(time).getTime() / (1000 * 60 * 60 * 24))+"d "+
                                        Math.floor((new Date(time).getTime() % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) +"h "+ 
                                        Math.floor((new Date(time).getTime() % (1000 * 60 * 60)) / (1000 * 60)) + "m "+
                                        Math.floor((time % (1000 * 60)) / 1000) + "s "
                                    }</h3>

                                    <p>We're working hard to finish the development of this site.</p>

                                    <a class="btn btn-outline-light btn-lg m-2" href="https://www.youtube.com/watch?v=c9B4TPnak1A" role="button" data-mdb-ripple-init
                                        rel="nofollow" target="_blank">Start tutorial</a>
                                    <a class="btn btn-outline-light btn-lg m-2" href="https://mdbootstrap.com/docs/standard/" target="_blank" data-mdb-ripple-init
                                        role="button">Download MDB UI KIT</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default ComingSoon;
