import './App.css';
import {Header} from '../header.js';
import { Footer } from '../footer.js';
import Carousel,{ autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

function Main() {
  return (
<>


{/* <div id="js-preloader" className="js-preloader">
      <div className="preloader-inner">
        <span className="dot"></span>
        <div className="dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div> */}
   
   <Header />
    
    <div className="main-banner wow fadeIn" id="top" data-wow-duration="1s" data-wow-delay="0.5s">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="left-content show-up header-text wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                  <div className="row">
                    <div className="col-lg-12">
                    <h2>AI in Judiciary <br></br></h2>
                    <h4>Legal services are more  <br></br></h4>
                    <h5>Accessible, Affordable, and Efficient.</h5>
                      <br></br>
                      <p>Bridge the gap between technology and Law.</p>
                      <br></br>
                    </div>
                    <div className="col-lg-12">
                      <div className="white-button first-button scroll-to-section">
                        <a href="/coming">Get started <i className="fab fa-next"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="right-image wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                  {/* <div className=""> */}
                    <Carousel plugins={['arrows','infinite',{
                            resolve: autoplayPlugin,
                            options: {
                              interval: 2000,
                            }
                          },
                        ]} style={{"width":"26rem"}}>
                      <div className="iphone">
                        <img src="assets/images/whatsapp_chat (1).png" />
                      </div>
                      <div className="iphone">
                        <img src="assets/images/whatsapp_chat (2).png" />
                      </div>
                      <div className="iphone">
                        <img src="assets/images/whatsapp_chat (3).png" />
                      </div>
                      <div className="iphone">
                        <img src="assets/images/whatsapp_chat (4).png" />
                      </div>
                      <div className="iphone">
                        <img src="assets/images/whatsapp_chat (5).png" />
                      </div>
                      <div className="iphone">
                        <img src="assets/images/whatsapp_chat (6).png" />
                      </div>
                      <div className="iphone">
                        <img src="assets/images/whatsapp_chat (7).png" />
                      </div>
                      <div className="iphone">
                        <img src="assets/images/whatsapp_chat (8).png" />
                      </div>
                    </Carousel>
                  {/* </div> */}
                {/* <Carousel>
                <div>
                    <img src="assets/images/pricing-table-01.png" />
                    <p className="legend">Legend 1</p>
                </div>
                <div>
                    <img src="assets/images/pricing-table-02.png" />
                    <p className="legend">Legend 2</p>
                </div>
                <div>
                    <img src="assets/images/pricing-table-01.png" />
                    <p className="legend">Legend 3</p>
                </div>
            </Carousel> */}

{/* <div id="myCarousel" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#myCarousel" data-slide-to="1"></li>
      <li data-target="#myCarousel" data-slide-to="2"></li>
    </ol>

    <div class="carousel-inner">
      <div class="item active">
        <img src="assets/images/pricing-table-01.png" alt="Los Angeles" style={{"width":"100%"}}/>
      </div>

      <div class="item">
        <img src="assets/images/pricing-table-02.png" alt="Chicago" style={{"width":"100%"}}/>
      </div>
    
      <div class="item">
        <img src="assets/images/pricing-table-01.png" alt="New york" style={{"width":"100%"}}/>
      </div>
    </div>

    <a class="left carousel-control" href="#myCarousel" data-slide="prev">
      <span class="glyphicon glyphicon-chevron-left"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" href="#myCarousel" data-slide="next">
      <span class="glyphicon glyphicon-chevron-right"></span>
      <span class="sr-only">Next</span>
    </a>
  </div> */}
                  {/* <div class="iphone">
                    <img src="assets/images/whatsapp.gif" alt=""/>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div id="services" className="services section">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="section-heading  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
              <h4>Amazing <em>Services &amp; Features</em> for you</h4>
              <img src="assets/images/heading-line-dec.png" alt=""/>
              {/* <p>If you need the greatest collection of HTML templates for your business, please visit <a rel="nofollow" href="https://www.toocss.com/" target="_blank">TooCSS</a> Blog. If you need to have a contact form PHP script, go to <a href="https://templatemo.com/contact" target="_parent">our contact page</a> for more information.</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="service-item first-service">
              <div className="icon"></div>
              <h4>ChatBot Application</h4>
              <p>Application designed to simulate human conversation, through text-based.</p>
              <div className="text-button">
                <a href="#">Read More <i className="fa fa-arrow-right"></i></a>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="service-item second-service">
              <div className="icon"></div>
              <h4>Get Relevant Court Cases</h4>
              <p>Get relevant cases in Supreme Court, High Court, District Court and Subordinate Courts with short explanation and Files.</p>
              <div className="text-button">
                <a href="#">Read More <i className="fa fa-arrow-right"></i></a>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="service-item third-service">
              <div className="icon"></div>
              <h4>Document Generation </h4>
              <p>Get your document legally notarized in minutes.</p>
              <div className="text-button">
                <a href="#">Read More <i className="fa fa-arrow-right"></i></a>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="service-item fourth-service">
              <div className="icon"></div>
              <h4>AI Wise Judge</h4>
              <p>AI Judge Recommendation Engine.</p>
              <div className="text-button">
                <a href="#">Read More <i className="fa fa-arrow-right"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div id="about" className="about-us section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 align-self-center">
            <div className="section-heading">
              <h4><em>Ai Justis</em> Why we need it.</h4>
              <img src="assets/images/heading-line-dec.png" alt=""/>
              <p>AI Justis has following product which you can use it based on the categories:</p>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="box-item">
                  <h4><a href="#">General Public</a></h4>
                  <p>Legal information, resources, and services for the general public.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box-item">
                  <h4><a href="#">Practitioner</a></h4>
                  <p>Helps Lawyer to find matching cases and arguments and counter cases.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box-item">
                  <h4><a href="#">Government and Regulatory Agencies </a></h4>
                  <p>Helps Police in manage your case & hearings.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box-item">
                  <h4><a href="#">Judges</a></h4>
                  <p>Helps Judges with Prediction Model and Legal Research.</p>
                </div>
              </div>
              <div className="col-lg-12">
                <p>Launching our cutting-edge AI Justice platform very soon! </p>
                <div className="gradient-button">
                  <a href="/coming">Start 14-Day Free Trial</a>
                </div>
                <span>*No Credit Card Required</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="right-image">
              <img src="assets/images/police-lawyer-public.png" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    {/* <div id="clients" className="the-clients">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="section-heading">
              <h4>Check What <em>The Clients Say</em> About Our App Dev</h4>
              <img src="assets/images/heading-line-dec.png" alt=""/>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eismod tempor incididunt ut labore et dolore magna.</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="naccs">
              <div className="grid">
                <div className="row">
                  <div className="col-lg-7 align-self-center">
                    <div className="menu">
                      <div className="first-thumb active">
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-4 col-sm-4 col-12">
                              <h4>David Martino Co</h4>
                              <span className="date">30 November 2021</span>
                            </div>
                            <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                              <span className="category">Financial Apps</span>
                            </div>
                            <div className="col-lg-4 col-sm-4 col-12">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span className="rating">4.8</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-4 col-sm-4 col-12">
                              <h4>Jake Harris Nyo</h4>
                              <span className="date">29 November 2021</span>
                            </div>
                            <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                              <span className="category">Digital Business</span>
                            </div>
                            <div className="col-lg-4 col-sm-4 col-12">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span className="rating">4.5</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-4 col-sm-4 col-12">
                              <h4>May Catherina</h4>
                              <span className="date">27 November 2021</span>
                            </div>
                            <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                              <span className="category">Business &amp; Economics</span>
                            </div>
                            <div className="col-lg-4 col-sm-4 col-12">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span className="rating">4.7</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-4 col-sm-4 col-12">
                              <h4>Random User</h4>
                              <span className="date">24 November 2021</span>
                            </div>
                            <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                              <span className="category">New App Ecosystem</span>
                            </div>
                            <div className="col-lg-4 col-sm-4 col-12">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span className="rating">3.9</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="last-thumb">
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-4 col-sm-4 col-12">
                              <h4>Mark Amber Do</h4>
                              <span className="date">21 November 2021</span>
                            </div>
                            <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                              <span className="category">Web Development</span>
                            </div>
                            <div className="col-lg-4 col-sm-4 col-12">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span className="rating">4.3</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                  <div className="col-lg-5">
                    <ul className="nacc">
                      <li className="active">
                        <div>
                          <div className="thumb">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="client-content">
                                  <img src="assets/images/quote.png" alt=""/>
                                  <p>“Lorem ipsum dolor sit amet, consectetur adpiscing elit, sed do eismod tempor idunte ut labore et dolore magna aliqua darwin kengan
                                    lorem ipsum dolor sit amet, consectetur picing elit massive big blasta.”</p>
                                </div>
                                <div className="down-content">
                                  <img src="assets/images/client-image.jpg" alt=""/>
                                  <div className="right-content">
                                    <h4>David Martino</h4>
                                    <span>CEO of David Company</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div className="thumb">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="client-content">
                                  <img src="assets/images/quote.png" alt=""/>
                                  <p>“CTO, Lorem ipsum dolor sit amet, consectetur adpiscing elit, sed do eismod tempor idunte ut labore et dolore magna aliqua darwin kengan
                                    lorem ipsum dolor sit amet, consectetur picing elit massive big blasta.”</p>
                                </div>
                                <div className="down-content">
                                  <img src="assets/images/client-image.jpg" alt=""/>
                                  <div className="right-content">
                                    <h4>Jake H. Nyo</h4>
                                    <span>CTO of Digital Company</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div className="thumb">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="client-content">
                                  <img src="assets/images/quote.png" alt=""/>
                                  <p>“May, Lorem ipsum dolor sit amet, consectetur adpiscing elit, sed do eismod tempor idunte ut labore et dolore magna aliqua darwin kengan
                                    lorem ipsum dolor sit amet, consectetur picing elit massive big blasta.”</p>
                                </div>
                                <div className="down-content">
                                  <img src="assets/images/client-image.jpg" alt=""/>
                                  <div className="right-content">
                                    <h4>May C.</h4>
                                    <span>Founder of Catherina Co.</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div className="thumb">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="client-content">
                                  <img src="assets/images/quote.png" alt=""/>
                                  <p>“Lorem ipsum dolor sit amet, consectetur adpiscing elit, sed do eismod tempor idunte ut labore et dolore magna aliqua darwin kengan
                                    lorem ipsum dolor sit amet, consectetur picing elit massive big blasta.”</p>
                                </div>
                                <div className="down-content">
                                  <img src="assets/images/client-image.jpg" alt=""/>
                                  <div className="right-content">
                                    <h4>Random Staff</h4>
                                    <span>Manager, Digital Company</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div className="thumb">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="client-content">
                                  <img src="assets/images/quote.png" alt=""/>
                                  <p>“Mark, Lorem ipsum dolor sit amet, consectetur adpiscing elit, sed do eismod tempor idunte ut labore et dolore magna aliqua darwin kengan
                                    lorem ipsum dolor sit amet, consectetur picing elit massive big blasta.”</p>
                                </div>
                                <div className="down-content">
                                  <img src="assets/images/client-image.jpg" alt=""/>
                                  <div className="right-content">
                                    <h4>Mark Am</h4>
                                    <span>CTO, Amber Do Company</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>          
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
  
    <div id="pricing" className="pricing-tables">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="section-heading">
              <h4>We Have The Best Pre-Order <em>Prices</em> You Can Get</h4>
              <img src="assets/images/heading-line-dec.png" alt=""/>
              <p>AI Justice Provided these recharges.</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="pricing-item-regular">
              <span className="price">Free / month</span>
              <h4>General Starter Plan</h4>
              <div className="icon">
                <img src="assets/images/pricing-table-01.png" alt=""/>
              </div>
              <ul>
                <li><b>10</b> Question per day</li>
                <li >Similar Court Cases</li>
                <li>Multi-lingual</li>
                <li>Paid Notary / Affidavit Document Generator</li>
                <li> File Case and <b>2</b> Case Reports</li>
                <li className="non-function">Reminders / Day Planner </li>
                <li className="non-function">On-call Support</li>
              </ul>
              <div className="border-button">
                <a href="/coming">Purchase This Plan Now</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="pricing-item-pro">
              <span className="price">₹ 999 / month</span>
              <h4>Business Plan App</h4>
              <div className="icon">
                <img src="assets/images/pricing-table-02.png" alt=""/>
              </div>
              <ul>
                <li><b>30</b> Question per day</li>
                <li >Similar Court Cases</li>
                <li>Multi-lingual</li>
                <li >Paid Notary / Affidavit Document Generator</li>
                <li> File Case and <b>10</b> Case Reports</li>
                <li>Document Analysis</li>
                <li className="non-function">On-call Support</li>
              </ul>
              <div className="border-button">
                <a href="/coming">Purchase This Plan Now</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="pricing-item-regular">
              <span className="price">Custom Pricing</span>
              <h4>Premium Plan</h4>
              <div className="icon">
                <img src="assets/images/pricing-table-01.png" alt=""/>
              </div>
              <ul>
                <li><b>Unlimited</b> Question per day</li>
                <li>Similar Court Cases</li>
                <li>Multi-lingual</li>
                <li>Notary / Affidavit Document Generator</li>
                <li> File Case and <b>Unlimited</b> Case Report</li>
                <li>Document Analysis</li>
                <li>On-call Support</li>
                <li>3rd Party Integration</li>
              </ul>
              <div className="border-button">
                <a href="/coming">Purchase This Plan Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  
    <Footer />
</>
  
    
  );
}


// ReactDOM.render(<DemoCarousel />, document.querySelector('.demo-carousel'));


export default Main;
